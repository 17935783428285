import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import DacList from '../views/DacList.vue'
import DacBrands from '../views/DacBrands.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/the_dac_list',
      name: 'dac_list',
      component: DacList
    },
    {
      path: '/dac_brands',
      name: 'dac_brands',
      component: DacBrands
    }
  ]
})
