<template>
  <v-container>
    <v-card light class="pa-4">

      <v-img :src="require(`@/assets/sd_dacs.jpg`)" max-height="320"></v-img>

      <v-card-title>
        the DAC list
      </v-card-title>

      <v-card-text class="text-body-1">

        <p>
          As you can see, this list still has to be populated <tt>:)</tt>
        </p>

        <v-data-table :headers="dac_headers" :items="dacs" :items-per-page="20" :search="search" class="elevation-1 card">

          <template v-slot:item="row">
            <tr>
              <td>{{row.item.brand}}</td>
              <td>{{row.item.type}}</td>
              <td>{{row.item.year}}</td>
              <td>{{row.item.price}}</td>
              <td>{{row.item.max_in_bitrate}}</td>
              <td>{{row.item.dac_chip_brand}} {{row.item.dac_chip_type}}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

    </v-card>

    <subscribe />

    <social />
  </v-container>
</template>

<script>
import DacService from '@/services/DacService.js';

export default {
  name: 'DacList',

  components: {
    Social: () => import('@/components/home/Social'),
    Subscribe: () => import('@/components/home/Subscribe')
  },

  data: function () {
    return {
      search: '',
      dac_headers: [
        { text: 'Brand', value: 'brand', align: 'left', class: 'title' },
        { text: 'Type', value: 'type', align: 'left', class: 'title' },
        { text: 'Year', value: 'year', align: 'left', class: 'title' },
        { text: 'Price', value: 'price', align: 'left', class: 'title' },
        { text: 'Max bitrate', value: 'max_bitrate', align: 'left', class: 'title' },
        { text: 'DAC chip', value: 'dac_chip', align: 'left', class: 'title' },
        { text: '' }
      ],
      dacs: []
    }
  },

  methods: {
    get_dacs() {
      DacService.get_dacs()
                .then(response => {
                  console.log('get_dacs got: ' + response.data.length + ' DACs');

                  this.dacs = response.data;

                  for (var i = 0; i < this.dacs.length; i++) {
                    var id = parseInt(this.dacs[i].id);

                    //                      this.dac_names[id] = this.dacs[i].name;
                    //                    this.dac_towns[id] = this.dacs[i].town;

                  }  // for

                });
    }  // get_dacs
  },
  mounted: function() {
    console.log('DacList mounted');
    setTimeout(this.get_dacs, 250);  // why the timeout ?

  }  // mounted
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 32px;
}

</style>
