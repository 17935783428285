<template>
  <v-container>
    <v-card light class="pa-4">

      <v-img :src="require(`@/assets/dalle2_dacs_scaled.jpg`)" max-height="320"></v-img>

      <v-card-title>
        DAC Brands
      </v-card-title>

      <v-card-text class="text-body-1">
        <p>
          I'm finding that every man and his dog sell a DAC these days <tt>:-)</tt>.
          The list has {{ brands.length }} entries so far.
        </p>
        <v-container>
          <v-row>
            <v-col cols="6" class="pa-0">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
            </v-col>
            <v-col cols="6" class="pa-4 pl-16">
              <p>
                The list can be sorted by brand, country, and founding year.
              </p>
            </v-col>
          </v-row>
        </v-container>

        <v-data-table :headers="brand_headers" :items="brands" :items-per-page="-1" :search="search" class="elevation-1 card">

          <template v-slot:item="row">
            <tr>
              <td v-if="row.item.operating == 0">{{row.item.name}} <span style="margin-left: 16px; font-size: 75%;">(no longer operating)</span></td>
              <td v-else>{{row.item.name}}</td>
              <td>{{row.item.country}}</td>
              <td style="text-align: center;">{{row.item.established != 0 ? row.item.established : '?' }}</td>
              <td style="text-align: center;">
                <a :href="row.item.website" target="_blank" v-if="row.item.website != ''">
                  <v-icon class="elevation-1">
                    mdi-open-in-new
                  </v-icon>
                </a>
              </td>
              <!-- <td style="text-align: center;">
                <span v-if="row.item.high_end == 1">medium</span>
                <span v-if="row.item.high_end == 2">high</span>
              </td> -->
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

    </v-card>

    <subscribe />

    <social />
  </v-container>
</template>

<script>
import DacService from '@/services/DacService.js';

export default {
  name: 'DacBrands',

  components: {
    Social: () => import('@/components/home/Social'),
    Subscribe: () => import('@/components/home/Subscribe')
  },

  data: function () {
    return {
      search: '',
      brand_headers: [
        { text: 'Brand', value: 'name', align: 'left', class: 'title' },
        { text: 'Country', value: 'country', align: 'left', class: 'title' },
        { text: 'Established', value: 'established', align: 'center', sortable: true, class: 'title' },
        { text: 'Website', value: 'website', align: 'left', class: 'title', sortable: false, filterable: false }
//        { text: 'High/Medium End', value: 'high_end', align: 'center', class: 'title', sortable: true, filterable: false }
      ],
      brands: []
    }
  },

  methods: {
    get_brands() {
      DacService.get_brands()
                .then(response => {
                  console.log('get_brands got: ' + response.data.length + ' brands');

                  this.brands = response.data;

                  for (var i = 0; i < this.brands.length; i++) {
                    var id = parseInt(this.brands[i].id);

                    //                      this.dac_names[id] = this.dacs[i].name;
                    //                    this.dac_towns[id] = this.dacs[i].town;

                  }  // for

                });
    }  // get_brands
  },
  mounted: function() {
    console.log('DacBrands mounted');
    setTimeout(this.get_brands, 250);  // why the timeout ?

  }  // mounted
}
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 32px;
}

</style>
