import axios from 'axios';

axios.defaults.withCredentials = true;

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_PATH || window.location.protocol + '//' + window.location.host,
  withCredentials: true,
  https: false,
  headers: {
    Accept: 'application/json'
  }
});

// mode: 'no-cors',

export default {
  get_dacs () {
    console.log('get_dacs, baseURL [' + apiClient.baseURL + ']');
    return apiClient.get('/api/get_dacs.php');
  },
  get_brands () {
    console.log('get_brands, baseURL [' + apiClient.baseURL + ']');
    return apiClient.get('/api/get_brands.php');
  }
  
};
